import React, { useEffect, useState, useMemo } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { configureStore, RootState } from './store';
import http, { injectStore } from './utils/http';
import {
  ScrollReset,
  LocaleUpdater,
  GoogleAnalytics,
  FixedLoader,
  PwaDynamicManifest
} from 'components';
import { generateRoutes } from './routes';
import { fetchUser } from './modules/auth/actions';
import { useAppSettings } from './modules/ui/hooks';
import configureTheme from './theme';

const store = configureStore();
const history = createBrowserHistory();
injectStore(store);

interface RouterProps {
  routes: RouteConfig[];
}

const AppRouter: React.FC<RouterProps> = ({ routes }) => {
  const dispatch = useDispatch();
  const { accessToken, isUserLoading } = useSelector((state: RootState) => ({
    accessToken: state.auth.accessToken,
    isUserLoading: state.auth.isUserLoading
  }));

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchUser.trigger());
    }
    // eslint-disable-next-line
  }, []);

  if (isUserLoading) {
    return <FixedLoader />;
  }

  return renderRoutes(routes);
};

const fetchConfig = async () => {
  return http.get('/system/config');
};

const App: React.FC = () => {
  const [routes, setRoutes] = useState();
  const { settings, setSettings } = useAppSettings();
  const { accessToken } = useSelector((state: RootState) => ({
    accessToken: state.auth.accessToken
  }));
  const appSettings = useSelector((state: RootState) => state.ui.app);
  const theme = useMemo(() => configureTheme(settings), [settings]);

  useEffect(() => {
    if (document && appSettings.webFavicon) {
      const icon = document.getElementById('app-favicon');
      if (icon) {
        icon.setAttribute('href', appSettings.webFavicon);
      }
    }
  }, [appSettings.webFavicon]);

  useEffect(() => {
    fetchConfig().then(config => {
      const routes = generateRoutes(config);
      setSettings(config);
      setRoutes(routes);
    });
    // eslint-disable-next-line
  }, [accessToken]);

  if (!routes) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <PwaDynamicManifest config={settings} />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router history={history}>
          <ScrollReset />
          <LocaleUpdater />
          <GoogleAnalytics />
          <AppRouter routes={routes} />
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default () => (
  <Provider store={store}>
    <App />
  </Provider>
);
