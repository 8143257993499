import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Checkbox,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import { useTranslation } from 'utils/translation';
import {
  ContextMenuState,
  isDir,
  isFile,
  Resource,
  RESOURCE_TYPE
} from '../../../../modules/resources';
import { Theme } from 'theme';
import { DateFormat, FileIcon } from 'components';
import { Selection } from '../../../../utils/use-selection';
import { usePagination } from '../../../../utils/use-pagination';
import { useCurrentDir, useDiskPagination } from '../../../../modules/ui/hooks';
import { useSortableDiskContext } from '../../utils/sortable.context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 70
  },
  content: {
    padding: 0,
    paddingBottom: '0 !important'
  },
  row: {
    cursor: 'pointer',
    '& td': {
      userSelect: 'none'
    }
  },
  iconType: {
    width: 30,
    paddingRight: 0,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  },
  name: {
    width: 'calc(60% - 30px)'
  },
  type: {
    width: '15%'
  },
  createdOn: {
    width: '30%',
    [theme.breakpoints.up('lg')]: {
      width: '20%'
    }
  },
  inner: {},
  title: {},
  selected: {
    '& $title': {
      fontWeight: 'bold'
    }
  }
}));

interface ResultsProps {
  className?: string;
  resources: Resource[];
  onClick: (resource: Resource) => void;
  onDoubleClick: (resource: Resource) => void;
  onDeleteMany: (resources: Resource[]) => void;
  selectedResource?: Nullable<Resource>;
  onItemRightClick(data: ContextMenuState): void;
}

const ResourceIcon = ({ item }: { item: Resource }) => {
  if (isDir(item)) {
    return <FolderIcon />;
  }

  if (isFile(item)) {
    return <FileIcon type={item.fileType} />;
  }

  return <FolderIcon />;
};

const ResourceLabel = ({ item }: { item: Resource }) => {
  const { t } = useTranslation('myDisk');
  let label = 'file';

  if (item.type === RESOURCE_TYPE.DIRECTORY) {
    label = 'folder';
  }

  if (isFile(item)) {
    label = item.fileType || item.type;
  }

  return <>{t(label)}</>;
};

const SHOW_ALL_MAX_VALUE = 1000;

const TableView = (props: ResultsProps & Selection<Resource>) => {
  const {
    className,
    resources = [],
    onDeleteMany,
    onClick,
    onDoubleClick,
    onItemRightClick,
    selectedResource,

    selectAll,
    deselectAll,
    selectOne,
    setLastSelected,
    resetLastSelected,
    selectedItems,
    isSelected,

    ...rest
  } = props;
  const classes = useStyles();
  const { t } = useTranslation('myDisk');
  const { currentDirId } = useCurrentDir();
  const { sortable, sort } = useSortableDiskContext();
  const { perPage, setPerPage } = useDiskPagination();
  const items = React.useMemo(() => sort(resources), [sort, resources]);
  const { page, setPage, currentPageItems } = usePagination<Resource>({
    items,
    perPage
  });

  React.useEffect(() => {
    setPage(0);
    // eslint-disable-next-line
  }, [currentDirId]);

  React.useEffect(() => {
    resetLastSelected();
    // eslint-disable-next-line
  }, [page]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? selectAll() : deselectAll();
  };

  const handleSelectOne = (
    event: React.ChangeEvent<HTMLInputElement>,
    resource: Resource
  ) => {
    selectOne(resource);
  };

  const handleRowClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    item: Resource
  ) => {
    // @ts-ignore
    if (event.target && event.target.getAttribute('type') === 'checkbox') {
      return false;
    }

    onClick(item);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value === 'All') {
      setPerPage(SHOW_ALL_MAX_VALUE);
    } else {
      setPerPage(+event.target.value);
    }
    setPage(0);
  };

  const handleContextMenuClick = (event: MouseEvent, resource: Resource) => {
    event.preventDefault();
    onItemRightClick({
      resource: resource,
      x: event.clientX,
      y: event.clientY
    });
  };

  const isItemDrawerSelected = (item: Resource) =>
    selectedResource &&
    selectedResource.type === item.type &&
    selectedResource.id === item.id;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedItems.length > 0 &&
                          selectedItems.length === resources.length
                        }
                        color="primary"
                        indeterminate={
                          selectedItems.length > 0 &&
                          selectedItems.length < resources.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell className={classes.iconType} />
                    <TableCell className={classes.name}>
                      <TableSortLabel
                        active={sortable.isActive('name')}
                        direction={sortable.direction}
                        onClick={() => sortable.setField('name')}>
                        {t('Name')}
                      </TableSortLabel>
                    </TableCell>
                    <Hidden mdDown>
                      <TableCell className={classes.type}>
                        <TableSortLabel
                          active={sortable.isActive('type')}
                          direction={sortable.direction}
                          onClick={() => sortable.setField('type')}>
                          {t('Type')}
                        </TableSortLabel>
                      </TableCell>
                    </Hidden>
                    <TableCell className={classes.createdOn}>
                      <TableSortLabel
                        active={sortable.isActive('createdAt')}
                        direction={sortable.direction}
                        onClick={() => sortable.setField('createdAt')}>
                        {t('Created on')}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageItems.map(item => (
                    <TableRow
                      hover
                      className={clsx(classes.row, {
                        [classes.selected]: isItemDrawerSelected(item)
                      })}
                      key={`${item.type}_${item.id}`}
                      selected={isSelected(item)}
                      onClick={event => handleRowClick(event, item)}
                      onDoubleClick={() => onDoubleClick(item)}
                      onContextMenu={(event: any) =>
                        handleContextMenuClick(event, item)
                      }>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected(item)}
                          color="primary"
                          onChange={event => handleSelectOne(event, item)}
                          value={isSelected(item)}
                        />
                      </TableCell>
                      <TableCell className={classes.iconType}>
                        <ResourceIcon item={item} />
                      </TableCell>
                      <TableCell>
                        <div className={classes.title}>{item.title}</div>
                      </TableCell>
                      <Hidden mdDown>
                        <TableCell>
                          <ResourceLabel item={item} />
                        </TableCell>
                      </Hidden>
                      <TableCell>
                        <DateFormat date={item.createdAt} withTime />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      // @ts-ignore
                      rowsPerPageOptions={[25, 50, 100, 'All']}
                      colSpan={5}
                      count={resources.length}
                      rowsPerPage={perPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      // @ts-ignore
                      SelectProps={{
                        renderValue: value =>
                          value === SHOW_ALL_MAX_VALUE ? 'Show all' : value
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </div>
  );
};

TableView.defaultProps = {
  onClick: () => {},
  onDoubleClick: () => {}
};

export { TableView };
