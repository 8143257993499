interface DirsConfig {
  types: {
    [key: string]: string;
  };
}

export default Object.freeze({
  types: {
    general: 'General',
    entity: 'Entity',
    'object-type': 'Object Type',
    'unique-object': 'Unique Object'
  }
}) as DirsConfig;
